.page-layout {
    max-width: 1440px;
    width: 100%;

    padding-left: 60px;
    padding-right: 60px;

    margin-left: auto;
    margin-right: auto;

    &:not(.--no-top-padding) {
        padding-top: 64px;
    }

    // overflow: hidden;

    @media screen and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.marketing-resource-center-layout {
    padding-top: 64px;
    padding-bottom: 64px;

    display: grid;
    //https://css-tricks.com/preventing-a-grid-blowout/
    grid-template-columns: auto auto minmax(0, 1fr);
    max-width: 100%;

    .resource-centre-hero-container {
        display: none;
        grid-column: span 3;
    }

    .divider {
        border-right: 1px solid #e5e5e5;
        margin: 0 64px;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 0;
    }

    @media only screen and(max-width: 992px) {
        display: flex;
        flex-direction: column;
        padding-top: 0;

        .top-navigation {
            order: -1;
        }

        .divider {
            display: none;
        }
        .content {
            //padding-top: 64px;
        }
    }
}
